@use "../global" as global;

.l-wrapper {
  width: 100vw;
  overflow-x: hidden;
  position: relative;
  background-color: #fff;
}

.l-section--mv {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  margin-bottom: 120px;
  z-index: 20;
  @include global.responsive(lg) {
    margin-bottom: 100px;
    min-height: 768px;
  }
}

.l-section {
  position: relative;
  z-index: 2;
}

.l-section.--bg {
  background-color: var(--color-primary);
	padding: 80px 0;
  @include global.responsive(md) {
		width: 95vw;
		margin-right: 5vw;
  }
}

.l-section + .l-section {
  margin-top: 80px;
	@include global.responsive(md) {
		margin-top: 160px;
	}
}

.l-section__inner {
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  @include global.responsive(sm) {
    padding: 0 50px;
  }
}