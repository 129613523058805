@import url('https://fonts.googleapis.com/css2?family=Anton&family=Noto+Sans+JP:wght@400;500;700&display=swap');

//color
:root {
    --color-base: #0e0e0e;
    --color-primary: #1e28be;
    --color-secondary: #fcfcfc;
    --color-white: #fff;

    //background-color
    --bg-base: #fbfbfb;
    --bg-primary: #fff7ef;
    --bg-secondary: #f8f8f8;

    // font-family
    --body-font: "Noto Sans JP", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
    --font-family-sans-serif: "Noto Sans JP", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
    --font-family-serif: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
    --font-family-original: "Anton", "Noto Sans JP", "游ゴシック体", sans-serif; //オリジナルフォント

    // line-height
    --line-height-base: 1.6;
    --line-height-large: 2;
    --line-height-small: 1.4;
}

// font-size
$body-font-size: clamp(.75rem, .5rem + .6vw, 1rem) !default; /* 12px-16px */
$font-size-base: clamp(.75rem, .5rem + .6vw, 1rem) !default; /* 12px-16px */
$font-size-title: clamp(.8125rem, .5rem + .8vw, 1.25rem) !default; /* 12px-20px */
$font-size-small: clamp(.75rem, .625rem + .4vw, .875rem) !default; /* 12px-14px */
$font-size-medium: clamp(1rem, .875rem + .4vw, 1.25rem) !default; /* 16px-20px */
$font-size-large: clamp(.9375rem, .75rem + .8vw, 1.5rem) !default; /* 15px-24px */
$font-size-xl: clamp(1.375rem, 1rem + 1vw, 1.875rem) !default; /* 22px-30px */
$font-size-xxl: clamp(1.875rem, 1.25rem + 1vw, 2.5rem) !default; /* 30px-40px */
$font-size-xxxl: clamp(2.625rem, 1.5rem + 5vw, 6.25rem) !default; /* 42px-100px */

// font-size-head
$font-size-h1: 40px !default;
$font-size-h2: 32px !default;
$font-size-h3: 28px !default;
$font-size-h4: 24px !default;
$font-size-h5: 20px !default;
$font-size-h6: 16px !default;

//link-color
$link-color: #000 !default;
$link-hover-color: darken($link-color, 5%) !default;
