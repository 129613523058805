@use "../global" as global;

.p-works__list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4%;
  row-gap: 20px;
  margin-top: 40px;
}

.p-works__item {
  width: 48%;
  @include global.responsive(md) {
    width: 22%;
  }
}

.p-works__item:nth-of-type(2n) {
  @include global.responsive(md, true) {
    margin-right: 0;
  }
}

.p-works__item:last-of-type {
  margin-right: 0;
}

.p-works__item__title {
  font-size: global.$font-size-title;
  font-weight: 700;
  margin-top: 10px;
  letter-spacing: 1px;
  @include global.responsive(md) {
    margin-top: 15px;
  }
}

.p-works__item__detail {
  font-size: global.$font-size-small;
  margin-top: 5px;
  @include global.responsive(md) {
    margin-top: 10px;
  }
}

.p-mail {
  display: block;
  text-align: center;

  a {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: var(--color-primary);
    padding: 80px 50px;
    text-align: center;
    background-color: #fff;
    border: 4px solid var(--color-primary);
    transition: all .5s ease-out;

    @include global.responsive(sm) {
      font-size: 20px;
      padding: 30px 100px;
    }
  
    &:hover {
      opacity: 1;
      color: #fff;
      background-color: var(--color-primary);
    }
  }
}