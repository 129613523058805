@use "../global" as global;

.p-member {
  @include global.responsive(md) {
    position: relative;
  }
}

.p-member__title {
  @include global.responsive(md) {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.p-member__list {
  @include global.responsive(md) {
    display: flex;
  }
}

.p-member__person {
  width: 100%;
  position: relative;
  margin-top: -80px;
  @include global.responsive(md) {
    width: 46%;
    margin-top: 0;
  }
}

.p-member__person:first-of-type {
  & .p-member__person__name {
    left: 25%;
    @include global.responsive(md) {      
      left: 15%;
    }
  }
  & .p-member__person__image {
    margin-left: auto;
    margin-right: 0;
    @include global.responsive(md) {
      margin-right: 10%;
    }
  }
  & .p-member__person__detail {
    padding: 60px 120px 60px 100px;
    margin-left: -100px;
    margin-right: -80px;
    clip-path: polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%);
    @include global.responsive(sm) {
      clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 0% 100%);
    }
  }
  @include global.responsive(md) {
    margin-right: 8%;
  }
}

.p-member__person:last-of-type {
  & .p-member__person__name {
    right: 5%;
  }
  & .p-member__person__image {
    margin-left: 0;
    margin-right: auto;
    @include global.responsive(md) {
      margin-left: 10%;
    }
  }
  & .p-member__person__detail {
    padding: 60px 100px 60px 120px;
    margin-left: -80px;
    margin-right: -100px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 25% 100%);
    @include global.responsive(sm) {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 15% 100%);
    }
  }
}

.p-member__person__name {
  position: absolute;
  top: 20%;
  font-size: global.$font-size-xxl;
  font-weight: 700;
  color: var(--color-primary);
  @include global.responsive(sm) {
    top: 30%;
  }
  @include global.responsive(md) {
    top: 20%;
  }
}

.p-member__person__image {
  width: 50%;
}

.p-member__person__details {
  filter: drop-shadow(4px 4px 20px rgba(20, 16, 4, .05));
}

.p-member__person__detail {
  position: relative;
  background-color: var(--color-secondary);
}

.p-member__person__detail::before {
  position: absolute;
}

.p-member__person__detail__sns__list {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
  margin-top: 30px;
}

.p-member__person__detail__sns__item {
  width: 40px;
  height: 40px;
  @include global.responsive(md) {
    width: 60px;
    height: 60px;
  }
}

.p-member__person__detail__sns__item a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  padding: 8px;
  transition: .5s background-color;
  @include global.responsive(md) {
    padding: 15px;
  }
  & svg {
    fill: var(--color-primary);
    transition: .5s fill;
  }
}

.p-member__person__detail__sns__item a:hover {
  background-color: var(--color-primary);
  opacity: 1;
  & svg {
    fill: var(--color-white);
  }
}