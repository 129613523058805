@use "../global" as global;

.c-btn {
    display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    border: none;
    font-size: 1rem;
    line-height: 1.5;
    padding: 10px 20px;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

.c-btnWrap {
    text-align: center;
}


// btn primary

.c-btn--primary {
    @extend .c-btn;
    font-weight: 700;
    font-family: var(--font-family-sans-serif);
    font-size: 14px;
    max-width: 466px;
    height: 80px;
    width: 100%;
    position: relative;
    transition: color .75s, background-color .75s;
    color: #fff;
    background-color: var(--color-primary);
    line-height: 56px;
    border: 2px solid var(--color-primary);
}

.c-btn--primary span {
    position: relative;
    padding-right: 30px;
}

.c-btn--primary span::before {
    content: "";
    position: absolute;
    top: 8px;
    width: 6px;
    height: 6px;
    right: 8px;
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
    transform: rotateZ(45deg);
    transition: all .5s;
}

.c-btn--primary span::after {
    content: "";
    position: absolute;
    top: 1px;
    width: 20px;
    height: 20px;
    right: 0px;
    border-radius: 50%;
    border: 1px solid #fff;
    transform: rotateZ(45deg);
    transition: all .5s;
}

.c-btn--primary:hover {
    color: var(--color-primary);
    background-color: #fff;
    opacity: 1;
}

.c-btn--primary:hover span::before,
.c-btn--primary:hover span::after {
    border-color: var(--color-primary);
}

// btn primary end



// btn secondary

.c-btn--secondary {
    @extend .c-btn;
    font-weight: 700;
    font-family: var(--font-family-sans-serif);
    font-size: 14px;
    max-width: 500px;
    height: 80px;
    width: 100%;
    position: relative;
    transition: color .75s, background-color .75s;
    color: #000;
    background-color: #fff;
    line-height: 60px;
    border: 1px solid #000;
}

.c-btn--secondary span {
    position: relative;
    padding-right: 30px;
}

.c-btn--secondary span:not([class])::before {
    content: "";
    position: absolute;
    top: 4px;
    width: 6px;
    height: 6px;
    right: 8px;
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    transform: rotateZ(45deg);
    transition: all .5s;
}

.c-btn--secondary span:not([class]):after {
    content: "";
    position: absolute;
    top: -3px;
    width: 20px;
    height: 20px;
    right: 0px;
    border-radius: 50%;
    border: 1px solid #000;
    transform: rotateZ(45deg);
    transition: all .5s;
}

.c-btn--secondary span.exterminal::before {
    content: "";
    position: absolute;
    top: 3px;
    width: 7px;
    height: 7px;
    right: 12px;
    transform: rotateZ(0);
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    transition: all .5s;
}

.c-btn--secondary span.exterminal::after {
    content: "";
    position: absolute;
    top: 2px;
    right: 16px;
    width: 1px;
    height: 11px;
    transform: rotateZ(45deg);
    transition: all .5s;
    background-color: #000;
}

.c-btn--secondary:hover {
    color: #fff;
    background-color: #000;
    opacity: 1;
}

.c-btn--secondary:hover span::before,
.c-btn--secondary:hover span::after {
    border-color: #fff;
}

.c-btn--secondary:hover span.exterminal::after {
    background-color: #fff;
}


// btn secondary end



// main-menu--------------------------------

.c-btn--menu {
    display: block;
    border: none;
    padding: 0;
    position: relative;
    width: 28px;
    height: 19px;
    cursor: pointer;
    z-index: 11;
    @include global.responsive(lg) {
        display: none;
    }
}

.c-btn--menu span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-primary);
    transition: .5s transform, .5s opacity, background-color .5s;
}

.c-btn--menu.is-close span {
    background-color: #000;
}

.c-btn--menu.is-open span:nth-of-type(1) {
    top: 0;
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
}

.c-btn--menu.is-open span:nth-of-type(2) {
    top: 9px;
    opacity: 1;
}

.c-btn--menu.is-open span:nth-of-type(3) {
    bottom: 0;
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);

}

.c-btn--menu.is-close span:nth-of-type(1) {
    top: 0;
    -webkit-transform: translateY(9px) rotate(-45deg);
    transform: translateY(9px) rotate(-45deg);
}

.c-btn--menu.is-close span:nth-of-type(2) {
    top: 9px;
    opacity: 0;
}

.c-btn--menu.is-close span:nth-of-type(3) {
    bottom: 0;
    -webkit-transform: translateY(-9px) rotate(45deg);
    transform: translateY(-9px) rotate(45deg);
}
