@use "../global" as global;

.p-business__list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
  margin-top: 40px;
  column-gap: 0;
  row-gap: 40px;
  @include global.responsive(md) {
    padding: 0;
    column-gap: 5%;
    row-gap: 0;
  }
}

.p-business__item {
  width: 100%;
  @include global.responsive(md) {
    width: 30%;
  }
}

.p-business__item__title {
  position: relative;
  font-size: global.$font-size-large;
  font-weight: 700;
  color: var(--color-white);
  text-align: center;
  margin-top: 10px;
  z-index: 5;
  @include global.responsive(md) {
    margin-top: 15px;
  }
}

.p-business__item__title span {
  background-color: var(--color-primary);
  padding: 2px 10px;
  z-index: 1;
}

.p-business__item__title::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: calc(50% - 1px);
  left: 0;
  background-color: var(--color-white);
  z-index: -1;
}

.p-business__item__detail {
  font-size: global.$font-size-small;
  color: var(--color-white);
  margin-top: 5px;
  @include global.responsive(md) {
    margin-top: 10px;
  }
}